<template>
    <div>

        <table-and-header
                ref="table"
                :import-list= "imports"
                :is-fetching-data= "isFetchingData"
                :is-importing-company-list= "isImportingCompanyList"
                @importCompanyList="handleCompanyListImport"
                @removeImport="removeImport"
        />

        <b-form-file
                id="fileUpload"
                v-model="importFile"
                class="mt-1"
                plain
                hidden
                accept=".csv"
                @change="fileUploaded"
        />
    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { useRouter } from '@core/utils/utils';
import { computed, ref, watch } from '@vue/composition-api';
import { BFormFile } from "bootstrap-vue";
import TableAndHeader from './TableAndHeader.vue';
import { errorToast, successToast } from '@/views/helpers/toast';
import { SOMETHING_WENT_WRONG } from '@/constants/errors';
import Import from '@/models/Import';
// import store from '@/store';

export default {
    components: {
        TableAndHeader,
        BFormFile
    },

    setup() {
        const importFile = null;
        const isBusy = true;
        const isLoading = ref(true);
        const imports = ref([]);
        const isFetchingData = ref(false);
        const isImportingCompanyList = ref(false);

        const { route } = useRouter();
        const routeParams = computed(() => route.value.params);

        watch(routeParams, () => {
        // eslint-disable-next-line no-use-before-define
            fetchImports();

        });

        const importBluePrint = {
            id: undefined,
            name: undefined,
            email: undefined,
        };

        const companyImport = ref(JSON.parse(JSON.stringify(importBluePrint)));

        const setCompanyImport = dataCompanyImport => ({
            id: dataCompanyImport.id,
            import_date: dataCompanyImport.import_date,
            file_name: dataCompanyImport.file_name,
            status: dataCompanyImport.status,
            remark: dataCompanyImport.remark,
        });

        const fetchImports = async() => {
            isFetchingData.value = true;
            imports.value = [];

            const importList = await Import.all();

            importList.forEach(dataCompanyImport => {
                const data = setCompanyImport(dataCompanyImport);
                imports.value.push(data);
            });

            isFetchingData.value = false;
        };
        fetchImports();

        const removeImport = importToDelete => {
            Import.delete(importToDelete.item.id, importToDelete.item)
                .then(() => {
                    imports.value = imports.value.filter( exitingImport =>
                    // eslint-disable-next-line no-use-before-define
                        exitingImport.id !== importToDelete.item.id
                    );

                    successToast("Record has been deleted successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to delete import.",
                        e
                    );
                });
        };

        const handleCompanyListImport = () => {
            const fileUpload = document.getElementById('fileUpload');
            fileUpload.click();
        };

        const fileUploaded = event => {
            console.log('setting company loading');
            isImportingCompanyList.value = true;
            Import.create(event.target.files[0])
                .then( payload => {
                    // eslint-disable-next-line no-use-before-define
                    const data = setCompanyImport(payload);

                    imports.value.push(data);
                    isImportingCompanyList.value = false;
                    successToast("The file has been uploaded and is being imported.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    isImportingCompanyList.value = false;
                    console.error(
                        "error while trying to import company list.",
                        e
                    );
                });
        };


        return {
            importFile,
            fetchImports,

            // Filter
            avatarText,

            // data
            isBusy,
            isLoading,

            isFetchingData,
            isImportingCompanyList,
            companyImport,
            imports,
            handleCompanyListImport,
            removeImport,
            fileUploaded
        };
    },

    mounted()
    {
        // just to make sure the menu bar is shown
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.table-container {
  position: relative;
  width: 100%;
}

.b-table[aria-busy="true"] + .table-spinner {
  /* this assumes that the spinner component has a width and height */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* make sure spinner is over table */
}

.preloader div:before {
    background-color: var(--primary-color) !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
