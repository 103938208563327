import axios from '@/apis/axios';

class Import
{
    static async all()
    {
        const response = await axios.get('/api/import-list');
        return response.data.data;
    }

    static async create(data)
    {
        const formData = new FormData();
        formData.append('file', data);

        const response = await axios.post('api/import-company-list', formData);
        return response.data.data;
    }

    static async delete(id, data)
    {
        const response =  await axios.delete(`api/import-company-list/delete/${id}`, data);
        return response;
    }
}

export default Import;
